<template>
<div style="width:100%;height:90vh">
  <QuestionTemplate :questionList='questions' @subCustomerPrice='subCustomerPrice'></QuestionTemplate>
  <!-- <van-dialog v-model="show" title="报废价格" show-cancel-button>
      <van-field
        v-model="smsCode"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码">
      </van-field>
  </van-dialog> -->
</div>
</template>

<script>
import {Dialog} from 'vant'
import {CSQuestion,SaveAsync} from '@/api/partOrder.js'
import QuestionTemplate from '@/components/questionTemplate'
export default {
  components:{
    QuestionTemplate,
    [Dialog.Component.name]:Dialog.Component
  },
  data(){
    return{
      questions:[],
      formBody:{},
      show:false
    }
  },
  methods:{
    subCustomerPrice(data){
      console.log(data)
      var body = window.sessionStorage.getItem('scrapPecesBody');
      body = JSON.parse(body)
      body.questionJson = JSON.stringify(data)
      console.log(body)
      // this.formBody.QuestionJson = JSON.stringify(data)
      this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
      SaveAsync(body).then(res=>{
        this.$toast.clear();
        console.log(res)
        if(res.status){
          this.$dialog.alert({
            title:'报废成功',
            message: '报废成功！报废价格为：'+res.data+"!",
          }).then(()=>{
            this.$router.go(-1)
          })
        }else{
          this.$dialog.alert({
            title:'报废失败',
            message: res.message,
          });
          // this.$router.go(-1)
        }
      })
    }
  },
  created(){
    console.log(this.questions)
  },
  mounted(){
    CSQuestion().then(res=>{
        this.questions = res
    })
  }
}
</script>

<style>

</style>